<template>
  <div>
    <h3>Заказы в работе</h3>
    <OrderList :orderListByStatus="ordersInProgress" />
  </div>
</template>

<script>
import OrderList from "../order/OrderList";

export default {
  name: "OrdersInProgressList",
  data: () => ({}),
  props: {
    orderListByStatus: Array,
  },
  components: {
    OrderList,
  },
  computed: {
    ordersInProgress() {
      return this.$store.getters.ordersInProgress;
    },
  },
};
</script>

<style scoped>
h3 {
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 20px;
}
</style>