<template>
  <div class="order-detail-inner">
    <div class="order-detail-top">
      <!-- cancel step  1 -->
      <template v-if="step1">
        <div class="order-top">
          <div class="order-maker"><span>Флорист</span>Розочкина Людмила</div>
          <h3>Вы хотите отказаться от сборки</h3>
          <div class="btn-holder">
            <button type="button" class="btn btn-primary btn-block">
              Вернуть в список новых заказов
            </button>
          </div>
          <div class="btn-holder">
            <button type="button" class="btn btn-primary btn-block">
              Изменить флориста
            </button>
          </div>
        </div>
      </template>
      <!-- cancel step  2 -->
      <template v-if="step2">
        <div class="order-top">
          <h6>
            Чтобы изменить флориста, сканируйте карту флориста, который возьмет
            заказ в работу
          </h6>
        </div>
        <OrderBarcode />
      </template>
    </div>
    <div class="order-detail-bottom">
      <button type="button" class="btn btn-info" @click="$emit('close-info')">
        Продолжить сборку заказа
      </button>
    </div>
  </div>
</template>

<script>
import OrderBarcode from "./OrderBarcode.vue";

export default {
  name: "OrderCancel",
  data: () => ({
    step1: true,
    step2: false,
  }),
  components: {
    OrderBarcode,
  },
};
</script>

<style scoped>
h2 {
  margin: 10px 0 32px;
}
h6 {
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
}
.btn-holder {
  margin-bottom: 20px;
}
</style>
