<template>
  <vue-tabs class="tab-list">
    <!-- new list -->
    <v-tab :title="statusCount(statusNew, newCount)">
      <div class="tab-content order-list-new">
        <template v-if="newCount > 0">
          <OrderList :orderListByStatus="ordersNew" />
        </template>
        <template v-else>
          <p>Новых заказов нет</p>
        </template>
      </div>
    </v-tab>
    <!-- canceled list -->
    <v-tab :title="statusCount(statusCanceled, canceledCount)">
      <div class="tab-content">
        <template v-if="canceledCount > 0">
          <OrderList :orderListByStatus="ordersCanceled" />
        </template>
        <template v-else>
          <p>Отмененных заказов нет</p>
        </template>
      </div>
    </v-tab>
  </vue-tabs>
</template>

<script>
import OrderList from "../order/OrderList";
import { VueTabs, VTab } from "vue-nav-tabs";
import "vue-nav-tabs/themes/vue-tabs.css";

export default {
  data: () => ({
    isActive: true,
    statusNew: "Новые ",
    statusCanceled: "Отмененные ",
    isActiveClass: true,
  }),
  props: {
    orderListByStatus: Array,
  },
  name: "OrdersNew",
  components: {
    OrderList,
    VueTabs,
    VTab,
  },
  computed: {
    ordersNew() {
      return this.$store.getters.ordersStatusNew;
    },
    ordersCanceled() {
      return this.$store.getters.ordersCanceled;
    },
    newCount() {
      return this.$store.getters.newOrdersCount;
    },
    canceledCount() {
      return this.$store.getters.canceledOrdersCount;
    },
  },
  methods: {
    statusCount(statusName, id) {
      return statusName + id;
    },
  },
};
</script>

<style scoped>
.order-list-new .card:not(.active) {
  pointer-events: none;
}
</style>
