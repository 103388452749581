<template>
  <div>
    <OrderItem
      v-for="(order, index) in orderListByStatus"
      :key="order.id"
      :order="order"
      @click.native="clickActiveOrder(order.status, order.id, index)"
      :class="{ active: activeElement == order.id }"
    ></OrderItem>
  </div>
</template>

<script>
import { eventBus } from "../../main";
import OrderItem from "./OrderItem.vue";

export default {
  name: "OrdersList",
  data: () => ({
    activeElement: 0,
  }),
  components: {
    OrderItem,
  },
  props: {
    orderListByStatus: Array,
    orderId: Number,
  },
  methods: {
    clickActiveOrder(statusId, id, index) {
      if (statusId === 1 && index != 0) {
        return false;
      } else {
        this.activeElement = id;
        eventBus.$emit("clickActiveOrder", {
          orderId: id,
          orderStatus: statusId,
        });
      }
    },
  },
};
</script>
