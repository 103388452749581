<template>
  <div class="orders-in-work">
    <template v-if="!isOrdersInWork()">
      <OrdersEmpty />
    </template>
    <template v-if="isOrdersInWork()">
      <OrdersInProgressList />
    </template>
  </div>
</template>

<script>
import OrdersEmpty from "./OrdersEmpty";
import OrdersInProgressList from "./OrdersInProgressList";

export default {
  name: "OrdersInProgress",
  data: () => ({
    isFlorist: true,
  }),
  components: {
    OrdersEmpty,
    OrdersInProgressList,
  },
  computed: {
    orders() {
      return this.$store.getters.allOrders;
    },
  },
  mounted() {
    this.$store.dispatch("getOrders");
  },
  methods: {
    isOrdersInWork() {
      return !!this.orders.find((order) => order.status_info.id == 5);
    },
  },
};
</script>


