<template>
  <div>
    <div>Сканируйте карту чтобы начать</div>
    <input type="text" v-model="barcode" autofocus @keyup.enter="getData" />
    <p class="info" v-if="!floristName">Флорист не выбран</p>
    <img src="../../assets/images/barcode.jpg" alt="" />
    isOpened: {{ isOpened }}
  </div>
</template>

<script>
export default {
  name: "OrderBarcode",
  props: {
    getFloristName: String,
    orderId: Number,
    isOpened: Boolean,
  },
  data: () => ({
    barcode: "",
    floristName: "",
  }),
  methods: {
    getData() {
      this.floristName = this.$store.getters.getFloristById(this.barcode).name;
      this.$store.commit({
        type: "setData",
        floristName: this.$store.getters.getFloristById(this.barcode).name,
        orderId: this.orderId,
      });
    },
  },
};
</script>

<style scoped>
.info {
  margin: 15px 0;
}
</style>
